
export const gunData = [
    {
        name: 'Ares',
        type: 'Heavy',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629510125/valorantWeapons/Ares2_ftcrdc.webp'
    },
    {
        name: 'Bucky',
        type: 'Shotgun',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629510125/valorantWeapons/Bucky2_lthhxu.webp'
    },
    {
        name: 'Bulldog',
        type: 'Rifle',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629510125/valorantWeapons/Bulldog2_ljvf0c.webp'
    },
    {
        name: 'Classic',
        type: 'Sidearm',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629510125/valorantWeapons/Classic2_updqg1.webp'
    },
    {
        name: 'Frenzy',
        type: 'Sidearm',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629510125/valorantWeapons/Frenzy2_ucgo1n.webp'
    },
    {
        name: 'Ghost',
        type: 'Sidearm',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629510125/valorantWeapons/Ghost2_dx0xdz.webp'
    },
    {
        name: 'Guardian',
        type: 'Rifle',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629510125/valorantWeapons/Guardian2_t0ghfc.webp'
    },
    {
        name: 'Judge',
        type: 'Shotgun',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629510125/valorantWeapons/Judge2_rbd9as.webp'
    },
    {
        name: 'Marshal',
        type: 'Sniper',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629510125/valorantWeapons/Marshal2_j33mqs.webp'
    },
    {
        name: 'Odin',
        type: 'Heavy',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629510125/valorantWeapons/Odin2_fpj8dj.webp'
    },
    {
        name: 'Operator',
        type: 'Sniper',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629510125/valorantWeapons/Operator2_qvuyqe.webp'
    },
    {
        name: 'Phantom',
        type: 'Rifle',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629510125/valorantWeapons/Phantom2_cusvqd.webp'
    },
    {
        name: 'Sheriff',
        type: 'Sidearm',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629510126/valorantWeapons/Sheriff2_n8bte3.webp'
    },
    {
        name: 'Shorty',
        type: 'Sidearm',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629510126/valorantWeapons/Shorty2_efwj1g.webp'
    },
    {
        name: 'Spectre',
        type: 'SMG',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629510126/valorantWeapons/Spectre2_tkudiw.webp'
    },
    {
        name: 'Stinger',
        type: 'SMG',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629510126/valorantWeapons/Stinger2_oxenxu.webp'
    },
    {
        name: 'TacticalKnife',
        type: 'Knife',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629510126/valorantWeapons/TacticalKnife2_ktxyrn.webp'
    },
    {
        name: 'Vandal',
        type: 'Rifle',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629510126/valorantWeapons/Vandal2_qifa4c.webp'
    }
 ]

 export default {
     gunData,
 }