import React from 'react';
import './App.css';
import Title from './components/title';
import FooterContent from './components/footer'
import Spinner from './components/spinner';
import {Row, Col} from 'antd';
import CookieConsent from "react-cookie-consent";

class App extends React.Component {

  componentDidMount(){
    document.body.style.background = "#222831";
   }

  render() {
    return (
      <div className="App">
        <Row>
          <Col span={24}>
            <Title></Title>
          </Col>
        </Row>

        <Row>
          <Col xs={2} sm={4} md={6} lg={8} xl={8}>
          </Col>

          <Col xs={20} sm={16} md={12} lg={8} xl={8}>
            <Spinner ></Spinner>
          </Col>

          <Col xs={2} sm={4} md={6} lg={8} xl={8}>
            {/* <AdCashBannerRight></AdCashBannerRight> */}
            
          </Col>

        </Row>

        <CookieConsent style={{ background: "#283b4e" }}>
          This website uses cookies to enhance the user experience.
        </CookieConsent>

        <Row justify="center" align="bottom">
          <Col xs={20} sm={16} md={12} lg={8} xl={{ span: 8, offset: 0}}>
            {/* <AmazonBanner></AmazonBanner> */}

          </Col>
        </Row>

        <Row justify="center" align="bottom">
          <Col xs={24} sm={14} md={12} lg={12} xl={10} >
            <ul className="footer-links">
              <li className="footer-links-item">
                <a href="https://www.gdprprivacynotice.com/live.php?token=IPNpcBdNxDb0oeoKsTdiL8g24565I0t3">Privacy Policy</a>
              </li>
              <li className="footer-links-item">
                <a href="mailto:elorip1@gmail.com">Contact Us</a>
              </li>
              <li className="footer-links-item">
                <a target="_blank" rel="noopener noreferrer" href="https://elo.rip/" title="League of Legends Randomizer">League Randomizer</a>
              </li>
            </ul>
            <FooterContent></FooterContent>
          </Col>
          <Col>
            
          </Col>
        </Row>
      </div>
    );
  }
}

export default App;
