import React from 'react';
import {agentData} from './agentData';
import {gunData} from './gunData';
import {Row, Col} from 'antd';
import './spinner.css'
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

export default class Spinner extends React.Component {
    constructor(props) {
        super(props);

        this.canvas = undefined;
        this.ctx = undefined;
        this.speed = 20;
        this.scale = 1;
        this.bouncingImages = [];

        this.handleClick = this.handleClick.bind(this);

        this.agentData = agentData;
        this.gunData = gunData;

        this.modifiedAgentData = [];
        this.modifiedGunData = [];

        this.state = {
            radioSetDisabled: false,
            startDisabled: false,
            hoveredButton: 0,
            selectedPosition: 'All',
            startButtonHover: false,
            randomType: 'Agents'
        };

        this.mouseLeave = this.mouseLeave.bind(this);
        this.mouseLeaveStartButton = this.mouseLeaveStartButton.bind(this);
    }

    componentDidMount() {
        this.canvas = this.refs.randomizerBox;
        this.ctx = this.canvas.getContext("2d");

        //Draw the "randomizerBox"
        this.canvas.width  = 1000;
        this.canvas.height = 1000;
        this.multiplyAgentData();
        this.populatebouncingImages();
    }

    handleClick() {
        this.setState({
            radioSetDisabled: true,
            startDisabled: true  
        });
        //this.populatebouncingImages();
        this.resetBouncingImages();
        this.update();
    }

    multiplyAgentData() {
        for(let i=0; i<11; i++) {
            for(let m=0; m < this.agentData.length; m++) {
                this.modifiedAgentData.push(this.agentData[m]);
            }
        }

        for(let i=0; i<11; i++) {
            for(let m=0; m < this.gunData.length; m++) {
                this.modifiedGunData.push(this.gunData[m]);
            }
        }
    }

    resetBouncingImages() {
        for (let i=0; i<this.bouncingImages.length; i++) {
            this.bouncingImages[i].bounces = 0;
            this.bouncingImages[i].x = 500;
            this.bouncingImages[i].y = 500;
            this.bouncingImages[i].xspeed = ((Math.random() < 0.5 ? -1 : 1) * (Math.random() * (20 - 0 + 1) + 5));
            this.bouncingImages[i].yspeed = ((Math.random() < 0.5 ? -1 : 1) * (Math.random() * (20 - 0 + 1) + 5));
        }
    }

    populatebouncingImages() {
        this.bouncingImages = [];
        var dataLength;
        dataLength = this.state.randomType === 'Agents' ?  this.modifiedAgentData.length : this.modifiedGunData.length;

        for (let i = 0; i < dataLength; i++) {
            this.bouncingImages[i] = {
                x: 500,
                y: 500,
                xspeed: ((Math.random() < 0.5 ? -1 : 1) * (Math.random() * (20 - 0 + 1) + 5)),
                yspeed: ((Math.random() < 0.5 ? -1 : 1) * (Math.random() * (20 - 0 + 1) + 5)),
                img: new Image(),
                largeImg: '',
                bounces: 0,
                lastBounce: undefined,
                championName: ''
            }
        }
   
        if(this.state.randomType === 'Agents') {
            for (let j = 0; j < this.modifiedAgentData.length; j++) { 
                this.bouncingImages[j].img.src = '/agentIcons/' + this.modifiedAgentData[j].name + '_icon2.webp';
                this.bouncingImages[j].largeImg = this.modifiedAgentData[j].fullImageUrl;
                this.bouncingImages[j].championName = this.modifiedAgentData[j].name;
            }
        } else {
            for (let j = 0; j < this.modifiedGunData.length; j++) { 
                this.bouncingImages[j].img.src = '/weaponImages/' + this.modifiedGunData[j].name + '.webp';
                this.bouncingImages[j].largeImg = this.modifiedGunData[j].fullImageUrl;
                this.bouncingImages[j].championName = this.modifiedGunData[j].name;
            }
        }
    }

    incrementBounces(icon) {
        icon.bounces = icon.bounces + 1;
        icon.lastBounce = Date.now();
    }

    clearCanvas() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }

    update() {
        setTimeout(() => {
            //Draw the canvas background
            this.ctx.fillStyle = '#000';
            
            this.clearCanvas();
            //Draw bouncingImages Logo and his background

            var options = 0;
            var potentialWinner;
            var dataLength;

            dataLength = this.state.randomType === 'Agents' ?  this.modifiedAgentData.length : this.modifiedGunData.length;

            for (let i = 0; i < dataLength; i++) { 
                if (this.bouncingImages[i].bounces < 3) {
                    this.ctx.drawImage(this.bouncingImages[i].img, this.bouncingImages[i].x, this.bouncingImages[i].y, this.bouncingImages[i].img.width*this.scale, this.bouncingImages[i].img.height*this.scale);
                
                    options++; 
                    if (options === 1) {
                        potentialWinner = i;
                    }
            
            //Move the logo
                    this.bouncingImages[i].x+=this.bouncingImages[i].xspeed;
                    this.bouncingImages[i].y+=this.bouncingImages[i].yspeed; 
                    this.checkHitBox(i);
                }
            }

            if (options === 1) {
                this.drawWinner(potentialWinner);
            } else if (options === 0 ) {
                var winner = 0;
                var winnerIndex;
                for (let k = 0; k < this.modifiedAgentData.length; k++) { 
                    if (this.bouncingImages[k].lastBounce > winner ) {
                        winner = this.bouncingImages[k].lastBounce;
                        winnerIndex = k;
                    }
                }
                this.drawWinner(winnerIndex);
            } else {
                //Check for collision 
                this.update();  
            }
        }, this.speed)
    }

    drawWinner(index) {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        let winner = new Image();

        if(this.state.randomType === 'Agents'){
            winner.onload = () => {
                this.ctx.drawImage(winner, 0, 5, 1000, 1000);
            }
            winner.src = this.bouncingImages[index].largeImg
            
            // this.ctx.drawImage(this.bouncingImages[index].largeImg, 0, 5, 1000, 1000);
        } else {
            winner.onload = () => {
                this.ctx.drawImage(winner, 100, 300, winner.width * .8, winner.height *.8);
            }
            winner.src = this.bouncingImages[index].largeImg
        }

        this.ctx.font = "100px  Inter";
        this.ctx.fillStyle = "#ececec";
        this.ctx.textAlign = "center";

        if(this.state.randomType === 'Agents') {
            this.ctx.fillText(this.bouncingImages[index].championName,  500, 80);
        } else {
            this.ctx.fillText(this.bouncingImages[index].championName,  500, 100);
        }
        
        document.getElementById("rngButton").disabled = false;
        
        this.setState({
            startDisabled: false,
            radioSetDisabled: false 
        });
    }
    
    //Check for border collision
    checkHitBox(i){
        if(this.bouncingImages[i].x+this.bouncingImages[i].img.width*this.scale >= this.canvas.width || this.bouncingImages[i].x <= 0){
            this.bouncingImages[i].xspeed *= -1;

            this.incrementBounces(this.bouncingImages[i]);
        }
            
        if(this.bouncingImages[i].y+this.bouncingImages[i].img.height*this.scale >= this.canvas.height || this.bouncingImages[i].y <= 0){
            this.bouncingImages[i].yspeed *= -1;

            this.incrementBounces(this.bouncingImages[i]);
        }    
    }

    mouseEnter(a) {
        this.setState({
            buttonNumber: a
        });
    }

    mouseLeave() {
        this.setState({
            buttonNumber: 0
        });
    }

    mouseEnterStartButton() {
        this.setState({
            startButtonHover: true
        });
    }

    mouseLeaveStartButton() {
        this.setState({
            startButtonHover: false
        })
    }

    onFilterSelection(selected) {
        this.setState({
            randomType: selected
        }, ()=> this.populatebouncingImages());
        
        this.clearCanvas();
    }


    render() {
        return (
            <div>

                <Row className="updatingAntMadeMeDoThis" justify="center" align="middle" gutter={[16, 24]}>
                    <Col>
                        <ButtonGroup disabled={this.state.radioSetDisabled} size="large">
                            <Button  onClick={ () => this.onFilterSelection("Agents")} onMouseEnter={ () => this.mouseEnter(1)} onMouseLeave={this.mouseLeave} style={{ marginBottom: "10px", fontFamily: "'Inter', sans-serif", color: "#fff",  backgroundColor: this.state.randomType === "Agents" ? "#407ebd" : this.state.buttonNumber === 1 ? "#446280" : "#283b4e"  }}> Agents </Button>
                            <Button  onClick={ () => this.onFilterSelection("Weapons")} onMouseEnter={ () => this.mouseEnter(2)} onMouseLeave={this.mouseLeave} style={{ marginBottom: "10px", fontFamily: "'Inter', sans-serif", color: "#fff", backgroundColor: this.state.randomType === "Weapons" ? "#407ebd" : this.state.buttonNumber === 2 ? "#446280" : "#283b4e"  }}> Weapons </Button>
                        </ButtonGroup>
                    </Col>
                </Row>

                <div className="wrapper">
                    <canvas className="secondary-color border" id="responsive-canvas" ref="randomizerBox"></canvas>
                </div>

                <div >
                    <Row className="updatingAntMadeMeDoThis" justify="center" align="middle" gutter={[16, 24]}>
                        <Col>
                            <Button disabled={this.state.startDisabled} id="rngButton" color="primary" disableElevation variant="contained" onClick={this.handleClick} style={{backgroundColor: this.state.startButtonHover ? "#446280" : '#283b4e', borderRadius: '4', minWidth: '144px', minHeight: '72px', fontFamily: 'Inter', fontSize: '2em', margin: '20px'}} onMouseEnter={ () => this.mouseEnterStartButton()} onMouseLeave={this.mouseLeaveStartButton}>Start</Button>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
} 


