import React from 'react';
import './title.css';

export default class Title extends React.Component {
    static propTypes = {}; 


    render() {
        return (
            <div>
                <h1 className="title"> Valorant Randomizer</h1>
            </div>
        )
    }

} 