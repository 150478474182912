
export const agentData = [
    {
        name: 'Astra',
        type: 'Controller',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629509484/valorantAgents/Astra__Full_qqd4xk.webp'
    },
    {
        name: 'Breach',
        type: 'Initiator',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629509484/valorantAgents/Breach__Full_wdr0hs.webp'
    },
    {
        name: 'Brimstone',
        type: 'Controller',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629509484/valorantAgents/Brimstone__Full_rslmyp.webp'
    },
    {
        name: 'Chamber',
        type: 'Sentinel',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1642389884/valorantAgents/Chamber__Full_rnjjal.webp'
    },
    {
        name: 'Cypher',
        type: 'Sentinel',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629509485/valorantAgents/Cypher__Full_kjivri.webp'
    },
    {
        name: 'Deadlock',
        type: 'Sentinel',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1699209846/valorantAgents/kf8gizeo5e2zq9s0r1gs.webp'
    },
    {
        name: 'Fade',
        type: 'Initiator',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1654653087/valorantAgents/Fade__Full_pdlvik.webp'
    },
    {
        name: 'Gekko',
        type: 'Initiator',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1679880650/valorantAgents/Gekko__Full_ebf8ej.webp',
    },
    {
        name: 'Harbor',
        type: 'Controller',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1679880645/valorantAgents/Harbor__Full_hbwbiw.webp',
    },
    {
        name: 'Iso',
        type: 'Duelist',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1699209846/valorantAgents/l4xa2esm93toubvh7tft.webp'
    },
    {
        name: 'Jett',
        type: 'Duelist',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629509484/valorantAgents/Jett__Full_ned8uo.webp'
    },
    {
        name: 'KAYO',
        type: 'Initiator',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629509484/valorantAgents/KAYO__Full_bfsvqg.webp'
    },
    {
        name: 'Killjoy',
        type: 'Controller',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629509485/valorantAgents/Killjoy__Full_v1riq6.webp'
    },
    {
        name: 'Neon',
        type: 'Duelist',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1642389884/valorantAgents/Neon__Full_ypkeab.webp'
    },
    {
        name: 'Omen',
        type: 'Controller',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629509485/valorantAgents/Omen__Full_e16enu.webp'
    },
    {
        name: 'Phoenix',
        type: 'Duelist',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629509485/valorantAgents/Phoenix__Full_vealxq.webp'
    },
    {
        name: 'Raze',
        type: 'Duelist',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629509484/valorantAgents/Raze__Full_bmr9bb.webp'
    },
    {
        name: 'Reyna',
        type: 'Duelist',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629509484/valorantAgents/Reyna__Full_ult9qj.webp'
    },
    {
        name: 'Sage',
        type: 'Sentinel',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629509485/valorantAgents/Sage__Full_nrj3qo.webp'
    },
    {
        name: 'Skye',
        type: 'Initiator',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629509485/valorantAgents/Skye__Full_va24rd.webp'
    },
    {
        name: 'Sova',
        type: 'Initiator',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629509485/valorantAgents/Sova__Full_opmi8i.webp'
    },
    {
        name: 'Viper',
        type: 'Controller',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629509485/valorantAgents/Viper__Full_zmfciq.webp'
    },
    {
        name: 'Yoru',
        type: 'Duelist',
        fullImageUrl: 'https://res.cloudinary.com/dtbixkkmc/image/upload/v1629509485/valorantAgents/Yoru__Full_ie0mlx.webp'
    }
 ]

 export default {
     agentData,
 }